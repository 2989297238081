import React from 'react'

import { Link } from 'gatsby'

import tvrperformance from '../../images/main/tvr-performance.png'
import lotuslogo from '../../images/main/lotus.png'
import tvrlogo from '../../images/main/tvr.png'
import alpinelogo from '../../images/main/alpine.png'
import tvrheritage from '../../images/main/tvr-heritage.png'

import { TinyButton as ScrollUpButton } from 'react-scroll-up-button'

import logo from '../../images/logo.png'

import './Footer.css'

const Footer = (props) => (
  <div>
    <div className="container">
      <div className="row">
        <div className="twelve columns contactUs">
          <h3 className="center-text">Get In Touch</h3>
          <p className="center-text">
            Copy about getting in touch ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo
            consequat. Duis aute irure dolor in reprehenderit in voluptate velit
            esse cillum.
          </p>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: '20px',
            }}
          >
            <Link to="/contact">
              <button className="button-primary">Contact Us</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
    <div className="full-primary-light">
      <div className="container">
        <div className="row">
          <div className="columns three">
            <div
              className="footer-manufacture-logo"
              style={{
                backgroundImage: `url(${tvrperformance})`,
              }}
            />
          </div>
          <div className="columns two">
            <div
              className="footer-manufacture-logo"
              style={{
                backgroundImage: `url(${lotuslogo})`,
              }}
            />
          </div>
          <div className="columns two">
            <div
              className="footer-manufacture-logo"
              style={{
                backgroundImage: `url(${tvrlogo})`,
              }}
            />
          </div>
          <div className="columns two">
            <div
              className="footer-manufacture-logo"
              style={{
                backgroundImage: `url(${alpinelogo})`,
              }}
            />
          </div>
          <div className="columns three">
            <div
              className="footer-manufacture-logo"
              style={{
                backgroundImage: `url(${tvrheritage})`,
              }}
            />
          </div>
        </div>
      </div>
    </div>

    <ScrollUpButton />
    <footer>
      <div className="container">
        <div className="row">
          <div className="twelve columns" style={{ margin: '0 0 25px' }}>
            <div>
              <div
                className="footer-logo"
                style={{ backgroundImage: `url(${logo})` }}
              />
              <p>01709 464 418</p>
              <p>enquries@john-ashley.co.uk</p>
              <p>22-28 Clough Road, Rotherham, S61 1RD</p>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom-container ">
        <div className="container">
          <div className="row">
            <div
              className="twelve columns"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexWrap: 'wrap',
              }}
            >
              <p>
                <span>&copy;</span>
                {` ${new Date().getFullYear()} ${props.siteTitle}`}
              </p>
              <span className="footer-divider">|</span>
              <p> Registered Company Number: 10801853</p>
              <span className="footer-divider">|</span>
              <a href="/privacy-policy">Privacy Policy</a>
              <span className="footer-divider">|</span>
              <a href="/terms">Terms & Conditions </a>
              <span className="footer-divider">|</span>
              <p>
                Designed and Hosted By{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://daffodil-marketing.com/services/website-design"
                >
                  Daffodil Marketing
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
)

export default Footer
