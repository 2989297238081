import React from 'react'
import { Link } from 'gatsby'

import './SideDrawer.css'

const SideDrawer = (props) => {
  let stateClass = ''
  if (props.show) {
    stateClass = 'open'
  }
  return (
    <div>
      <nav className={`side-drawer ${stateClass}`}>
        <ul className="moble-navigation">
          {props.siteNavigation.map((navItem, index) => (
            <li key={index}>
              <Link to={navItem.location} onClick={props.backdropClick}>
                {navItem.title}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <div
        className={`backdrop ${stateClass}`}
        role="button"
        tabIndex="-1"
        onClick={props.backdropClick}
      />
    </div>
  )
}

export default SideDrawer
